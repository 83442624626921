<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="mx-2 mt-3">
		<template v-if="!loading">
			<div
				class="row no-gutters justify-content-center">
				<div
					:class="['xs', 'sm'].includes(windowWidth) ? 'pb-3' : 'px-2'"
					class="col-12">
					<div class="row mt-1">
						<div class="col-12">
							<b-tabs
								v-if="tabs.length > 1"
								v-model="tabIndex">
								<template v-for="(tabInfo, index) in tabs">
									<b-tab :key="index">
										<template slot="title">
											<span
												:class="tabIndex === index ? 'nav-link ' + $router.options.linkActiveClass : 'text-gray-dark'"
												class="list-group-item btn text-left">
												{{ translate(tabInfo.translate_key) }}
											</span>
										</template>
									</b-tab>
								</template>
							</b-tabs>
							<b-card>
								<b-card-body
									v-if="tabs[tabIndex].name === 'products'"
									class="pt-0">
									<b-alert
										v-if="cartCountry === 'SG'"
										show>
										<div v-html="translate('am_pm_bundle_sg_description')" />
										<a
											style="font-weight: 600;"
											class="pointer"
											target="_blank"
											:href="`${S3_PATH}/assets/products/${theme}/info/am_pm_bundle_sg_brochure.png`">
											{{ translate('see_more') }}
										</a>
									</b-alert>
									<div
										v-for="(item, key) in configurableProducts"
										:key="item.attributes.sku"
										:class="key < configurableProducts.length - 1 ? 'border border-secondary border-top-0 border-left-0 border-right-0 pb-2 mb-2' : ''"
										class="row">
										<div class="col">
											<configurable-product
												:product="item.attributes"
												:code-name="item.attributes.code_name"
												:sku="item.attributes.sku"
												config-name="flavor"
												:thumbnail="item.attributes.thumbnail"
												:cart-country="cartCountry"
												:stacked-layout="['xs', 'sm'].includes(windowWidth)">
												<div class="row">
													<div
														v-for="(child, sku) in item.attributes.children"
														:key="child.code_name"
														class="col-12 col-md-6 mb-3">
														<product-configuration
															:backorder-info="showBackorderInfo ? child.backorder_info : null"
															:configuration="child.code_name"
															:init-qty="0"
															:stacked-layout="['xs', 'sm'].includes(windowWidth)"
															@change="addToSelectedProducts(sku, $event)" />
													</div>
												</div>
											</configurable-product>
										</div>
									</div>
									<div
										v-for="(item, key) in simpleProducts"
										:key="item.attributes.sku"
										class="col-12 border border-secondary border-bottom-0 border-left-0 border-right-0 pt-2 mt-2"
										:class="{
											'pl-0': ['xs', 'sm'].includes(windowWidth),
											'pb-3': key === simpleProducts.length - 1,
										}">
										<div class="row no-gutters">
											<div class="col-12">
												<simple-product
													:backorder-info="showBackorderInfo ? item.attributes.backorder_info : null"
													:code-name="item.attributes.code_name"
													:bvs="Number(item.attributes.bvs)"
													:init-qty="0"
													:price="item.attributes.price"
													:thumbnail="item.attributes.thumbnail"
													:stacked-layout="['xs', 'sm'].includes(windowWidth)"
													@change="addToSelectedProducts(item.attributes.sku, $event)" />
											</div>
										</div>
									</div>
									<div
										v-if="!!subtotal && !loading && !isPreviewOutdated"
										class="row no-gutters mb-2 px-2 mt-2">
										<div
											class="col-12 d-flex"
											:class="['xs'].includes(windowWidth) ? 'justify-content-center' : 'justify-content-end'">
											<span :class="['xs'].includes(windowWidth) ? 'h3' : 'h2'">
												{{ translate('subtotal') }} {{ subtotal }}
											</span>
										</div>
									</div>
									<div
										v-if="allProducts.length > 0"
										class="row no-gutters mt-2">
										<div class="col col-12 no-gutters justify-content-end text-right">
											<button
												:disabled="disableActions"
												type="button"
												:style="['xs'].includes(windowWidth) ? '' : 'max-width: 200px;'"
												aria-label="Close"
												class="btn w-100 btn-secondary btn-lg mr-2"
												:class="['xs'].includes(windowWidth) ? 'mb-2' : ';'"
												@click="previewTotals()">
												{{ translate('preview_total') }}
											</button>
											<button
												:disabled="disableActions"
												type="button"
												:style="['xs'].includes(windowWidth) ? '' : 'max-width: 200px;'"
												aria-label="Close"
												class="btn w-100 btn-primary btn-lg"
												@click="createCart()">
												{{ translate('continue') }}
											</button>
										</div>
									</div>
								</b-card-body>
								<b-card-body
									v-else-if="tabs[tabIndex].name === 'packs'"
									class="p-0">
									<gift-order-pack
										:packs="packs"
										:products="packProducts"
										:cart-country="cartCountry"
										:initial-selected-products="initialProductsQty"
										@add-to-selected-products="addToSelectedProducts"
										@updateCartId="updateCartId" />
								</b-card-body>
							</b-card>
						</div>
					</div>
				</div>
			</div>
		</template>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="!!allProducts.length"
			:class="loading ? 'mt-4' : ''"
			class="mx-auto" />
	</div>
</template>
<script>
import IsLoading from '@/components/Loading';
import ConfigurableProducts from '@/mixins/ConfigurableProducts';
import WindowSizes from '@/mixins/WindowSizes';
import { GiftOrders, Products as productMessages, Purchase } from '@/translations';
import ConfigurableProduct from './ConfigurableProduct';
import ProductConfiguration from './ProductConfiguration';
import SimpleProduct from './SimpleProduct';
import GENERAL_INFO from '@/util/GeneralInformation';
import GiftOrderPack from '@/views/GiftOrders/components/Products/Pack.vue';
import { GIFT_PRODUCTS_SORTED_SKU as sortList } from '@/settings/Products';
import Products from '@/util/Products';
import Cart from '@/util/Cart';
import GiftOrderCart from '@/util/GiftOrderCart';
import { S3_PATH } from '@/settings/Images';

export default {
	name: 'ProductsEdit',
	messages: [GiftOrders, productMessages, Purchase],
	components: {
		GiftOrderPack,
		ConfigurableProduct,
		IsLoading,
		ProductConfiguration,
		SimpleProduct,
	},
	mixins: [ConfigurableProducts, WindowSizes],
	props: {
		productsLoading: {
			type: Boolean,
			default: false,
		},
		cartCountry: {
			type: String,
			default: '',
		},
		allProducts: {
			type: Array,
			default: () => ([]),
		},
		initialSelectedProducts: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			alert: new this.$Alert(),
			selectedProducts: {},
			disableActions: true,
			isPreviewOutdated: false,
			tabIndex: 0,
			packs: new GENERAL_INFO(),
			initialProductsQty: {},
			products: new Products(),
			packProducts: [],
			cartEstimatedTotals: new Cart(),
			createGiftOrderCart: new GiftOrderCart(),
			S3_PATH,
			theme: process.env.VUE_APP_THEME,
		};
	},
	computed: {
		showBackorderInfo() {
			// eslint-disable-next-line camelcase
			return this.simpleProducts.some((item) => item.attributes.backorder_info?.will_backorder ?? false)
				|| this.configurableProducts.some((item) => {
					const children = !Array.isArray(item.attributes.children) ? Object.values(item.attributes.children) : item.attributes.children;
					// eslint-disable-next-line camelcase
					return children.some((child) => child.backorder_info?.will_backorder ?? false);
				});
		},
		loading() {
			return this.productsLoading;
		},
		simpleProducts() {
			return this.allProducts.filter((product) => !product.attributes.has_configurations);
		},
		configurableProducts() {
			return this.allProducts.filter((product) => product.attributes.has_configurations);
		},
		tabs() {
			const tabs = [
				{
					name: 'products',
					translate_key: 'products',
				},
			];

			if (this.isJns()) {
				tabs.push({
					name: 'packs',
					translate_key: 'go_back_to_packs',
				});
			}
			return tabs;
		},
		// Subtotal
		subtotal() {
			try {
				const { subtotal } = this.cartEstimatedTotals.data.response.data.response;
				return subtotal;
			} catch (error) {
				return '';
			}
		},
	},
	watch: {
		selectedProducts: {
			handler(newVal) {
				// Actions are only enabled if there are products with quantity
				this.disableActions = Object.values(newVal).reduce((acc, val) => acc + (val || 0), 0) === 0;

				// Preview is outdated if the selected products change after preview
				this.isPreviewOutdated = true;
			},
			deep: true,
		},
		initialSelectedProducts(newVal) {
			this.selectedProducts = window.structuredClone(newVal);
		},
		allProducts() {
			this.resetInitialProducts();
		},
		tabIndex() {
			this.resetInitialProducts();
		},
	},
	mounted() {
		if (this.isJns()) {
			this.packs.getRegisterPacks(this.cartCountry, 'purchase');
		}
		this.getPacksProducts();
	},
	methods: {
		confirm() {
			this.$emit('confirm', window.structuredClone(this.selectedProducts));
		},
		addToSelectedProducts(sku, qty) {
			this.$set(this.selectedProducts, sku, qty);
		},
		// previewTotals() {
		// 	this.isPreviewOutdated = false;
		// 	this.$emit('preview-totals', window.structuredClone(this.selectedProducts));
		// },
		resetInitialProducts() {
			const initialProducts = {};
			// If is products tabs, initial products qty is empty
			if (this.tabs[this.tabIndex].name === 'products') {
				this.initialProductsQty = {};
				this.selectedProducts = {};
				return;
			}

			// If is packs tabs, initial products qty is the initial selected products
			this.simpleProducts.forEach((product) => {
				initialProducts[product.attributes.sku] = {
					value: this.initialSelectedProducts[product.attributes.sku] || 0,
				};
			});

			this.configurableProducts.forEach((product) => {
				const children = Object.keys(product.attributes.children);
				children.forEach((child) => {
					initialProducts[child] = {
						value: this.initialSelectedProducts[product.attributes.sku] || 0,
					};
				});
			});

			this.initialProductsQty = initialProducts;
			this.selectedProducts = initialProducts;
		},
		getPacksProducts() {
			this.products.getProducts(this.cartCountry, null, { isRegister: true, sponsor_id: this.$user.details().id }).then((response) => {
				const productsData = response;
				productsData.sort((a, b) => {
					const aIndex = sortList.indexOf(a.attributes.sku);
					const bIndex = sortList.indexOf(b.attributes.sku);

					if (aIndex === -1) return 1;
					if (bIndex === -1) return -1;

					return aIndex - bIndex;
				});
				this.packProducts = productsData;
			});
		},
		// Update cartId trigger from pack option
		updateCartId(cartId, cartData) {
			this.$emit('updateCartId', cartId, cartData);
		},
		// Preview totals from selected products
		async previewTotals() {
			this.alert.loading(this.translate('loading_title'), this.translate('updating_info_loading_text'), {
				allowEscapeKey: false,
				allowOutsideClick: false,
			});
			// The above promises can be rewritten as follows:
			try {
				const payload = {
					country: this.cartCountry,
					products: this.selectedProducts,
					gift_order: true,
				};

				await this.cartEstimatedTotals.getCartTotalsEstimates(payload);
				this.isPreviewOutdated = false;
			} catch (error) {
				this.alert.toast('error', this.translate('default_error_message'), { timer: 6000 });
			} finally {
				this.alert.close();
			}
		},
		// Create cart from products selection (NO PACKS)
		async createCart() {
			this.alert.loading(this.translate('loading_title'), this.translate('updating_info_loading_text'), {
				allowEscapeKey: false,
				allowOutsideClick: false,
			});
			// The above promises can be rewritten as follows:
			try {
				const payload = {
					country: this.cartCountry,
					products: this.selectedProducts,
				};

				const response = await this.createGiftOrderCart.createGiftOrderCart(payload);
				this.$emit('updateCartId', response.response.cart_id, null);
				this.alert.close();
			} catch (error) {
				const productError = this.createGiftOrderCart?.errors?.errors?.products ?? [];
				if (productError.length) {
					this.alert.toast('error', productError[0], { timer: 6000 });
				} else {
					this.alert.toast('error', this.translate('default_error_message'), { timer: 6000 });
				}
			}
		},
	},
};
</script>
