<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<template
			v-if="!loading">
			<!-- Dropdown to choose pack -->
			<div
				v-if="flatPacks.length"
				class="row">
				<!-- Choose pack dropdown -->
				<div
					id="pack-name-select"
					:class="showPackDetail ? 'col-10' : 'col-12'">
					<label
						for="pack-name-select"
						class="label"
						style="float: left;">
						{{ translate('short_title') }}
						<span class="text-danger">*</span>
					</label>
					<item-select
						v-if="packNameOptions"
						id="countries"
						class="pt-2"
						:selected-item.sync="selectedPackObj"
						:placeholder="translate('short_title')"
						:options="packNameOptions" />
				</div>
				<!-- View pack details -->
				<div
					v-if="showPackDetail"
					class="col-2 p-0 mt-4 pt-2">
					<a
						class="pointer text-muted font-weight-light"
						@click="formatProducts()">
						<i class="fa fa-eye" />
						{{ translate('view_details') }}
					</a>
				</div>
			</div>
			<is-loading
				v-if="!loading"
				:loading-label="translate('loading')"
				:no-data-label="translate('data_not_found')"
				:loading="loading"
				:has-data="!!flatPacks.length"
				:class="loading ? 'mt-4' : ''"
				class="mx-auto" />
			<!-- Pack options by type -->
			<div
				v-if="selectedPackData">
				<!-- Pack with fixed products and selectable pack Qty -->
				<div v-if="selectedPackData.allow_multiple_purchase">
					<multiple-purchase-pack
						:selected-pack="selectedPack"
						:pack-data="selectedPackData"
						@update:packQty="updateSelectedPacksQty"
						@toggleDisableConfirm="handleDisableConfirm" />
				</div>
				<!-- If pack is fully amount customizable -->
				<div v-else-if="selectedPackData.fully_amount_customizable">
					<product-list-pack
						:pack-data="selectedPackData"
						:selected-pack="selectedPack"
						:initial-selected-products="initialSelectedProducts"
						:products="products"
						:cart-country="cartCountry"
						@updateSelection="updateSelectedProducts"
						@toggleDisableConfirm="handleDisableConfirm" />
				</div>
				<!-- If pack is minimum amount customizable -->
				<div v-else-if="selectedPackData.minimum_amount_customizable">
					<product-list-pack
						:pack-data="selectedPackData"
						:selected-pack="selectedPack"
						:initial-selected-products="initialSelectedProducts"
						:products="products"
						:cart-country="cartCountry"
						@updateSelection="updateSelectedProducts"
						@toggleDisableConfirm="handleDisableConfirm" />
				</div>
			</div>
			<!-- Buttons -->
			<b-row>
				<b-col
					cols="12"
					class="mt-2">
					<button
						:disabled="disableConfirm"
						type="button"
						:style="['xs'].includes(windowWidth) ? '' : 'max-width: 200px;'"
						aria-label="Close"
						class="btn w-100 btn-primary btn-lg float-right mt-2"
						@click="createPackCart()">
						{{ translate('continue') }}
					</button>
				</b-col>
			</b-row>
			<register-pack-products-modal
				:pack-products="packProducts"
				:pack-id="selectedPack"
				:type="packageName.purchase"
				@close="showRegisterPackProductsModal = false;" />
		</template>
	</div>
</template>
<script>
import DocumentTitle from '@/mixins/DocumentTitle';
import WindowSizes from '@/mixins/WindowSizes';
import CommonMix from '@/views/Register/mixins/Common';
import Sponsor from '@/views/Register/mixins/Sponsor';
import { Purchase, Store as storeMessages } from '@/translations';
import PublicStore from '@/mixins/PublicStore';
import ConfigurableProducts from '@/mixins/ConfigurableProducts';
import { currency } from '@/config/Filters';
import ItemSelect from '@/components/ItemSelect';
import ProductListPack from '@/views/GiftOrders/components/Products/ProductListPack';
import GiftOrderCart from '@/util/GiftOrderCart';
import MultiplePurchasePack from '@/views/GiftOrders/components/Products/MultiplePurchasePack';
import RegisterPackProductsModal from '@/components/RegisterPackProductsModal';
import { PACKAGE_NAME_BY_FLOW } from '@/settings/Register';
import EventBus from '@/util/eventBus';

/*
 * In this component we manage the logic of the packs in the gift orders.
 * We have components for each type of pack and this component has to return a cartId with everything in the pack.
 */
export default {
	name: 'GiftOrderPack',
	components: {
		RegisterPackProductsModal, MultiplePurchasePack, ProductListPack, ItemSelect,
	},
	messages: [storeMessages, Purchase],
	filters: {
		currency,
	},
	mixins: [CommonMix, DocumentTitle, Sponsor, WindowSizes, PublicStore, ConfigurableProducts],
	props: {
		cartCountry: { // Country of the cart
			type: String,
			default: '',
		},
		packs: { // Pack data from endpoint
			type: Object,
			default: () => ({}),
		},
		products: { // All available products for gift orders
			type: Array,
			default: () => ([]),
		},
		initialSelectedProducts: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			// Usefully vars
			alert: new this.$Alert(),
			// Enable/Disable confirm button
			disabledButtonByPack: {},
			disableConfirm: true,
			showRegisterPackProductsModal: false,
			packageName: PACKAGE_NAME_BY_FLOW,
			// Only products available for packs
			packProducts: [],
			// Current pack data
			selectedPackObj: null,
			// Variables to manage the selected pack
			createGiftOrderCart: new GiftOrderCart(),
			selectedPackProducts: {},
			packQty: 0,
		};
	},
	computed: {
		packNameOptions() {
			return this.flatPacks.map((pack) => {
				let badge = '';
				const { group } = pack;
				const { colors } = pack;
				if (colors) {
					const { background, text } = colors;
					const style = `background-color: ${background}; color: ${text};`;
					badge = `<span class="ml-2 badge" style="${style}">${ this.translate(`pack_${group}`) }</span>`;
				}
				return ({
					value: pack.id,
					text: `${badge} ${this.translate(pack.name)}`,
				});
			});
		},
		flatPacks() {
			const flatPacks = [];
			this.packsData.forEach((pack) => {
				const { packages } = pack.attributes;
				Object.keys(packages).forEach((key) => {
					const packageData = packages[key];
					flatPacks.push({
						id: `${pack.id}-${key}`,
						name: pack.id,
						type: pack.attributes.type,
						group: pack.attributes.info.group,
						...packageData,
					});
				});
			});

			return flatPacks;
		},
		selectedPack() {
			try {
				return this.selectedPackObj.value.split('-')[0];
			} catch (error) {
				return '';
			}
		},
		selectedPackState() {
			try {
				return this.selectedPackObj.value.split('-')[1];
			} catch (error) {
				return '';
			}
		},
		selectedPackData() {
			try {
				return this.flatPacks.find((pack) => pack.id === this.selectedPackObj.value);
			} catch (error) {
				return {};
			}
		},
		loading() {
			return !!this.packs.data.loading;
		},
		// Computed props from packs var
		packsData() {
			try {
				return this.packs.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		showPackDetail() {
			try {
				return this.selectedPackObj && (!this.selectedPackData.allow_multiple_purchase || false);
			} catch (e) {
				return false;
			}
		},
	},
	watch: {
		showRegisterPackProductsModal(newVal) {
			if (newVal) {
				EventBus.$emit('showRegisterPackProductsModal');
			}

			if (!newVal) {
				EventBus.$emit('hideRegisterPackProductsModal');
			}
		},
	},
	methods: {
		formatProducts() {
			this.packProducts = this.selectedPackData.products_pack;
			this.showRegisterPackProductsModal = true;
		},
		updateSelectedProducts(products) {
			this.selectedPackProducts = products;
		},
		updateSelectedPacksQty(qty) {
			this.packQty = qty;
		},
		handleDisableConfirm(disableConfirm, packCode) {
			this.disabledButtonByPack[packCode] = disableConfirm;
			this.disableConfirm = typeof this.disabledButtonByPack[this.selectedPack] === 'undefined' || this.disabledButtonByPack[this.selectedPack];
		},
		async createPackCart() {
			const payload = {
				sponsor: null,
				step: 'product',
				country: this.cartCountry,
				gift_order: true,
			};

			const sendProducts = this.selectedPackData.fully_amount_customizable || this.selectedPackData.minimum_amount_customizable;
			payload.product = {
				package_code_name: this.selectedPack,
				package_option: this.selectedPackState,
				package_qty: this.selectedPackData.allow_multiple_purchase ? this.packQty : 1,
				is_pack: 1,
				products: sendProducts ? this.selectedPackProducts : {},
			};

			this.alert.loading(this.translate('loading_title'), this.translate('updating_info_loading_text'), {
				allowEscapeKey: false,
				allowOutsideClick: false,
			});
			// The above promises can be rewritten as follows:
			try {
				const response = await this.createGiftOrderCart.createGiftOrderPackCart(payload);
				const cartData = {
					is_pack: 1,
					package_code_name: this.selectedPack,
					package_option: this.selectedPackState,
					package_qty: this.selectedPackData.allow_multiple_purchase ? this.packQty : 1,
				};
				this.$emit('updateCartId', response.response.cart_id, cartData);
				this.alert.close();
			} catch (error) {
				console.error(error);
				this.alert.close();
				const productError = this.createGiftOrderCart?.errors?.errors?.products ?? [];
				if (productError.length) {
					this.alert.toast('error', productError[0], { timer: 6000 });
				} else {
					this.alert.toast('error', this.translate('default_error_message'), { timer: 6000 });
				}
			}
		},
	},
};
</script>
