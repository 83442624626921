<!-- eslint-disable vue/no-v-html -->
<template>
	<div style="height: 67vh; overflow-y: scroll; overflow-x: hidden">
		<div class="row justify-content-center">
			<div class="col-12">
				<div class="row mb-1">
					<div class="col">
						<div class="alert alert-info w-100">
							<div class="row no-gutters">
								<div class="col">
									<strong>{{ translate('gift_order_info_title') }}</strong>
									<br><br>
									<span v-html="translate('gift_order_info_intro')" />
									<br><br>
									<ol>
										<li
											v-for="(item, index) in giftOrderDescription"
											:key="index">
											{{ translate(`gift_order_info_${index}`) }}
											<ol
												v-if="item > 0"
												style="list-style-type: lower-alpha; padding-bottom: 0;">
												<li
													v-for="subItem in item"
													:key="`s_${index}_${subItem}`">
													{{ translate(`gift_order_info_${index}_${subItem}`) }}
												</li>
											</ol>
										</li>
									</ol>
								</div>
							</div>
						</div>
					</div>
				</div>
				<b-alert
					v-if="hasPurchaseErrors"
					class="mt-2"
					show
					variant="danger">
					<h5 class="font-weight-bold">
						<i class="fa fa-exclamation-triangle" /> {{ translate('verify_following_errors') }}:
					</h5>
					<ul class="h6">
						<template v-for="(error, index) in purchaseErrors">
							<li :key="index">
								{{ error }}
							</li>
						</template>
					</ul>
				</b-alert>
				<b-row no-gutters>
					<b-col
						cols="12"
						md="8">
						<flow-info-group class="my-3">
							<flow-info-section
								:title="`1. ${translate('select_market')}`"
								active-color="dark"
								hide-cancel
								hide-save
								:init-edit="isEditing.country"
								no-inner-spacing
								@toggled="step = 'country'">
								<template v-slot:edit>
									<i class="fa fa-pencil mr-1" />
									{{ translate('change') }}
								</template>
								<country-overview
									v-if="!isEditing.country"
									:selected-country="cartCountryCode" />
								<country-edit
									v-if="isEditing.country"
									:selected-country="cartCountryCode"
									@country-selected="setCountry" />
							</flow-info-section>
							<div v-if="!isEditing.country">
								<flow-info-section
									:title="`2. ${translate('products_information')}`"
									active-color="dark"
									hide-cancel
									hide-save
									:init-edit="isEditing.products"
									no-inner-spacing
									@toggled="step = 'products'">
									<template v-slot:edit>
										<i class="fa fa-pencil mr-1" />
										{{ translate('change') }}
									</template>
									<products-overview
										v-show="!isEditing.products"
										:cart-products="cartProducts"
										:cart-options="cartOptions" />
									<products-edit
										v-show="isEditing.products"
										:cart-country="cartCountryCode"
										:products-loading="products.loading || products.data.loading"
										:all-products="allProducts"
										:initial-selected-products.sync="selectedProducts"
										@updateCartId="updateCartId" />
								</flow-info-section>
								<flow-info-section
									v-if="!isEditing.products"
									:title="`3. ${translate('address_information')}`"
									active-color="dark"
									hide-cancel
									hide-save
									:collapse="!hasSavedData.shipping && !isEditing.shipping"
									:init-edit="isEditing.shipping"
									no-inner-spacing
									@toggled="step = 'shipping'">
									<template v-slot:edit>
										<i class="fa fa-pencil mr-1" />
										{{ translate('change') }}
									</template>
									<div>
										<shipping-edit
											v-show="isEditing.shipping"
											:initial-country-code="cartCountryCode"
											:validation-errors="validationErrors"
											@clear-error="clearValidationError"
											@address-selected="setAddress($event)" />
									</div>
									<div
										v-show="!isEditing.shipping"
										:class="['xs'].includes(windowWidth) ? '' : 'px-2'">
										<shipping-overview
											:initial-country-code="cartCountryCode"
											:shipping-method-name="selectedShippingMethod"
											:address-id="`${shippingAddressId}`" />
									</div>
								</flow-info-section>
								<flow-info-section
									v-if="!isEditing.products"
									:title="`4. ${translate('payment_information')}`"
									active-color="dark"
									hide-cancel
									hide-save
									:hide-edit="!hasSavedData.shipping"
									:collapse="!(isEditing.payment || isEditing.all || hasSavedData.payment)"
									:init-edit="isEditing.payment"
									no-inner-spacing
									@toggled="step = 'payment'">
									<template v-slot:edit>
										<i class="fa fa-pencil mr-1" />
										{{ translate('change') }}
									</template>
									<payment-edit
										v-show="isEditing.payment"
										:initial-country-code="country"
										:cart-country="cartCountryCode"
										:payment-method-conditions="paymentMethodConditions"
										:cart-id="cartId"
										:cart-total="cartNumericTotal"
										:system-loading="systemLoading"
										@update:payment="setPaymentMethod" />
									<payment-overview
										v-show="!isEditing.payment"
										:initial-country-code="country"
										:billing-address-id="+billingAddressId"
										:payment-method-name="paymentMethodName"
										:last-digits="lastDigits"
										:card-type="cardType"
										:cc-address="ccAddress" />
								</flow-info-section>
							</div>
						</flow-info-group>
					</b-col>
					<b-col
						cols="12"
						md="4">
						<order-summary
							:cart-id="cartId"
							:cart-options="cartOptions"
							:cart-loading="systemLoading"
							:free-products-info="freeProductsInfo"
							:cart-totals="cartTotals"
							:cart-small-text-totals="cartSmallTextTotals"
							:free-shipping-info="freeShippingOptions"
							:cart-items="cartProducts"
							:cart-total="cartTotal"
							:total-volume="totalVolume"
							:terms-and-policies="termsAndPolicies"
							:apply-free-bottle="applyFreeBottle"
							:free-bottle-message="freeBottleMessage"
							:editing-other-steps="!isEditing.all"
							:shipping-address-id="+shippingAddressId"
							:payment-method-name="paymentMethodName"
							:wallet-type="walletType"
							:wallet-password="walletPassword"
							:credit-card-id="+cardId"
							:billing-address-id="+billingAddressId"
							:cart-country-code="cartCountryCode"
							:style="['xs', 'sm'].includes(windowWidth) ? '' : `top: 0 !important; z-index: 1 !important;`"
							:class="['xs', 'sm'].includes(windowWidth) ? 'mx-2 mt-2' : 'sticky-top m-3'"
							class="mw-100 mb-3"
							:show-backorder-agreement="hasBackorderProducts"
							@cartValidationError="goToProducts"
							@invalidRequest="handleInvalidRequest"
							@serverError="goToProducts"
							@purchaseClick="handleOrderClick"
							@purchaseErrors="handlePurchaseErrors"
							@cartReplaced="handleCartReplaced"
							@reloadCart="getTotals" />
					</b-col>
				</b-row>
			</div>
		</div>
		<b-alert
			v-if="hasPurchaseErrors && ['xs','sm'].includes(windowWidth)"
			class="mt-2"
			show
			variant="danger">
			<h5 class="font-weight-bold">
				<i class="fa fa-exclamation-triangle" /> {{ translate('verify_following_errors') }}:
			</h5>
			<ul class="h6">
				<template v-for="(error, index) in purchaseErrors">
					<li :key="index">
						{{ error }}
					</li>
				</template>
			</ul>
		</b-alert>
	</div>
</template>
<script>
import Products from '@/util/Products';
import GiftOrderCart from '@/util/GiftOrderCart';
import FlowInfoGroup from '@/components/FlowInfo/Group';
import FlowInfoSection from '@/components/FlowInfo/Section';
import WindowSizes from '@/mixins/WindowSizes';
import {
	FORBIDDEN, NOT_FOUND, UNPROCESSABLE,
} from '@/settings/Errors';
import {
	DESCRIPTION_LIST,
} from '@/settings/GiftOrders';
import {
	Products as productMessages,
	Validations,
	Countries as countriesMessages,
	GiftOrders,
	Purchase,
} from '@/translations';
import Cart from '@/util/Cart';

import CountryEdit from './components/Country/Edit';
import CountryOverview from './components/Country/Overview';
import ProductsEdit from './components/Products/Edit';
import ProductsOverview from './components/Products/Overview';
import ShippingEdit from './components/Shipping/Edit';
import ShippingOverview from './components/Shipping/Overview';
import PaymentEdit from './components/Payment/Edit';
import PaymentOverview from './components/Payment/Overview';
import OrderSummary from './components/OrderSummary';
import {
	BLACKLIST_NON_SELECTABLE as blacklistNonSelectable,
} from '@/settings/ManualOrder';
import CartData from './mixins/CartData';
import CommonMix from './mixins/Common';
import PaymentMethods from '@/mixins/PaymentMethods';
import { GIFT_PRODUCTS_SORTED_SKU as sortList } from '@/settings/Products';

export default {
	name: 'CreateGiftOrder',
	components: {
		FlowInfoGroup,
		FlowInfoSection,
		CountryEdit,
		CountryOverview,
		ProductsEdit,
		ProductsOverview,
		ShippingEdit,
		ShippingOverview,
		PaymentEdit,
		PaymentOverview,
		OrderSummary,
	},
	mixins: [CartData, CommonMix, WindowSizes, PaymentMethods],
	messages: [
		productMessages,
		Validations,
		countriesMessages,
		GiftOrders,
		Purchase,
	],
	data() {
		return {
			// Utilities
			alert: new this.$Alert(),
			step: 'country',
			descriptionList: DESCRIPTION_LIST,

			// Products
			selectedProducts: {},
			products: new Products(),
			productOptions: [],
			allProducts: [],
			order: {
				products: {},
			},

			// Shipping
			shippingAddressId: 0,
			selectedShippingMethod: '',
			isPickupAddress: false,

			// Payment
			paymentMethod: {},

			// Cart
			cart: new Cart(),
			cartEstimatedTotals: new Cart(),
			addCartProduct: new Cart(),
			cartId: '',
			blacklistNonSelectable,

			purchaseErrors: [],
		};
	},
	computed: {
		// Utilities
		isEditing() {
			return {
				country: this.step === 'country',
				products: this.step === 'products',
				shipping: this.step === 'shipping',
				payment: this.step === 'payment',
				all: this.step === 'all',
			};
		},
		isEditingAnyStep() {
			return Object.values(this.isEditing).some((value) => value);
		},
		hasSavedData() {
			return {
				country: !!this.cartCountryCode,
				products: !!Object.entries(this.order.products).length,
				// If all fields are empty then assume that there's no saved data
				shipping: !!this.shippingAddressId,
				payment: !!Object.entries(this.paymentMethod).length,
			};
		},
		loading() {
			return [
				this.cart.data.loading,
				this.createGiftOrderCart.data.loading,
				this.addCartProduct.data.loading,
			].some((loading) => loading);
		},
		giftOrderDescription() {
			const company = this.isJns() ? 'jns' : 'default';
			return this.descriptionList[company];
		},

		hasCountrySelected() {
			return !!this.cartCountryCode;
		},
		validateButtonDisabled() {
			return !['totals'].includes(this.step)
				|| this.cartTotalsLoading
				|| !this.hasCountrySelected;
		},

		// Cart
		cartTotalsLoading() {
			return !!this.cart.data.loading;
		},
		cartData() {
			try {
				const { data } = this.cart.data.response.data;
				return data;
			} catch (error) {
				return {};
			}
		},

		// Payment
		billingAddressId() {
			try {
				return this.paymentMethod.payment.billing.address_id || 0;
			} catch (error) {
				return 0;
			}
		},
		ccAddress() {
			try {
				if (this.paymentMethod.payment.billing.address_id) {
					return {};
				}
				return this.paymentMethod.payment.billing;
			} catch (error) {
				return {};
			}
		},
		paymentMethodName() {
			try {
				return this.paymentMethod.payment.payment_method.name;
			} catch (error) {
				return '';
			}
		},
		lastDigits() {
			try {
				return this.paymentMethod.payment.payment_method.last_digits;
			} catch (error) {
				return '';
			}
		},
		cardType() {
			try {
				return this.paymentMethod.payment.payment_method.card_type;
			} catch (error) {
				return '';
			}
		},
		cardId() {
			try {
				return this.paymentMethod.payment.payment_method.card_id;
			} catch (error) {
				return '';
			}
		},
		walletType() {
			try {
				return this.paymentMethod.payment.payment_method.wallet_type;
			} catch (error) {
				return '';
			}
		},
		walletPassword() {
			try {
				return this.paymentMethod.payment.payment_method.wallet_password;
			} catch (error) {
				return '';
			}
		},
		freeShippingOptions() {
			try {
				if (!this.isPickupAddress) {
					return this.freeShippingInfo;
				}
				return {};
			} catch (error) {
				return {};
			}
		},
		hasPurchaseErrors() {
			return !!this.purchaseErrors.length;
		},
	},
	watch: {
		language() {
			this.productOptions.forEach((item) => {
				item.text = item.attributes.name;
			});
		},
		cartNumericTotal(total) {
			this.setAmountToPay(total);
		},
	},
	mounted() {
		document.body.style.overflow = 'hidden';
	},
	beforeDestroy() {
		document.body.style.overflow = '';
	},
	methods: {
		// Country
		setCountry(countryCode) {
			if (typeof countryCode !== 'undefined') {
				this.cartCountryCode = countryCode;
				this.removeUsedCart();
				this.getProducts();
				this.setDefaultValues();
				this.step = 'products';
			}
		},

		// Products
		getProducts() {
			this.products.getProducts(this.cartCountryCode, null, { giftOrder: true }).then((response) => {
				const productsData = response;
				productsData.sort((a, b) => {
					const aIndex = sortList.indexOf(a.attributes.sku);
					const bIndex = sortList.indexOf(b.attributes.sku);

					if (aIndex === -1) return 1;
					if (bIndex === -1) return -1;

					return aIndex - bIndex;
				});
				this.allProducts = productsData;
				this.productOptions = [...this.allProducts];
			});
		},
		goToProducts() {
			this.step = 'products';
		},
		async updateCartId(cartId, cartPackOptions) {
			this.alert.loading(this.translate('loading_title'), this.translate('updating_info_loading_text'), {
				allowEscapeKey: false,
				allowOutsideClick: false,
			});
			// The above promises can be rewritten as follows:
			try {
				await this.removeUsedCart();
				this.step = 'shipping';
				this.cartId = cartId;

				let payload = {
					country: this.cartCountryCode,
				};

				if (cartPackOptions !== null) {
					payload = {
						...payload,
						...cartPackOptions,
					};
				}
				this.cartOptions = payload;
				await this.cart.getCart(this.cartId, payload, 'gift_order');
				this.alert.close();
			} catch (error) {
				const productError = this.createGiftOrderCart?.errors?.errors?.products ?? [];
				if (productError.length) {
					this.alert.toast('error', productError[0], { timer: 6000 });
				} else {
					this.alert.toast('error', this.translate('default_error_message'), { timer: 6000 });
				}
			}
		},

		// Shipping
		setAddress(addressData) {
			const shippingAddressId = addressData.address_id;
			this.isPickupAddress = addressData.is_pickup;
			this.alert.loading(this.translate('loading_title'), this.translate('updating_info_loading_text'), {
				allowEscapeKey: false,
				allowOutsideClick: false,
			});

			this.shippingAddressId = shippingAddressId;
			const payload = {
				shipping: {
					shipping_address: {
						address_id: this.shippingAddressId,
					},
				},
				cart_id: this.cartId,
				country: this.cartCountryCode,
			};
			this.updateGiftOrderInfo(payload).then((response) => {
				this.selectedShippingMethod = response.response.shipping_method;
				this.getTotals();
				this.alert.close();
				this.step = 'payment';
			}).catch((errors) => {
				if ([...NOT_FOUND, ...FORBIDDEN].includes(errors.status)) {
					this.handleInvalidRequest(errors);
				}
				if (UNPROCESSABLE.includes(errors.status)) {
					const { cart_id: cartId } = errors.errors;
					if (typeof cartId !== 'undefined') {
						let response = '';
						cartId.forEach((item) => { response += `${item} \n`; });
						this.alert.toast('error', response, { timer: 6000 });
						setTimeout(() => {
							this.goToProducts();
						}, 6000);
					}

					if (typeof errors.errors['shipping.shipping_address.address_id'] !== 'undefined') {
						let response = '';
						errors.errors['shipping.shipping_address.address_id'].forEach((item) => { response += `${item} \n`; });
						this.alert.toast('error', response, { timer: 6000 });
						this.step = 'shipping';
					}
				}
			});
		},

		// Payment
		setPaymentMethod(paymentMethod) {
			this.paymentMethod = paymentMethod;
			this.step = 'all';
		},

		handleInvalidRequest() {
			this.alert.close();
			this.alert.toast('error', this.translate('default_error_message'), { timer: 6000 });
			this.step = 'country';
			this.setDefaultValues();
		},
		handleOrderClick() {
			this.purchaseErrors = [];
		},
		handlePurchaseErrors(errors) {
			this.purchaseErrors = errors;
		},
		handleCartReplaced(cartId) {
			this.cartId = cartId;
			this.getTotals();
		},
		setDefaultValues() {
			this.shippingAddressId = 0;
			this.selectedShippingMethod = '';
			this.selectedProducts = {};
			this.createGiftOrderCart = new GiftOrderCart();
			this.cart = new Cart();
		},
		removeUsedCart() {
			if (this.cartId !== '') {
				this.removeCart.removeCart(this.cartId);
			}
		},
	},
};
</script>
<style>
/* Not scoped due to it needing to affect the style of a child component, so using an ID instead */
#countries-item-select .form-group {
	margin-bottom: 0.25rem;
}
</style>
